<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-navigation-drawer class="blue-grey lighten-5" permanent expand-on-hover>
    <v-list class="item-title">
      <v-list-item class="title">
        <v-list-item-title class="text-h8"> {{ nameModule }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list nav dense>
      <template v-for="({ name, link, icon, rol }, i) in subMenu">
        <v-list-item v-if="userRoles.includes(rol)" :key="i" :to="link">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item>
      </template>

    </v-list>
  </v-navigation-drawer>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                     ###### -->
<!-- #################################################################################### -->

<script>
import { mapState } from "vuex";

export default {
  name: 'SubMenu',
  props: {
    subMenu: {
      type: Array,
      default: () => []
    },
    nameModule: String
  },
  data() {
    return {
      userRoles: {}
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },

  computed: {
    ...mapState(["auth"]),
  },

}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.v-navigation-drawer {
  height: 91vh !important;
}

.title div {
  padding-bottom: 25px !important;
}

::v-deep .v-navigation-drawer__content .item-title {
  height: 37px !important;
}

.v-list-item--active {
  color: #0a5cb8 !important;
}
.v-icon{
  color: #a3a8b7;
}
</style>